.news-activity {
    width: calc(100% - 1rem);
    margin: 0.5rem;
    height: calc(100% - 1rem);
    display: flex;
    flex-direction: column;

    &-thumb {
        position: absolute;
        box-sizing: border-box;
        background-image:url(/assets/images/school-image.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 20%;
        width: calc(100% - 1rem);
        height: 10rem;
        border-radius: 0.5rem;
    }

    &-body {
        position: absolute;
        top: 11rem;
        box-sizing: border-box;
        width: calc(100% - 1rem);
        height: calc(100% - 12.5rem);
        color: #999999;
        font-size: 1.0rem;
        line-height: 1.5rem;
        overflow: hidden;

        &-container {
            position: absolute;
            top: 0px;
            box-sizing: border-box;
            width: 100%;
            height: 500px;
            color: #999999;
            font-size: 1.0rem;
            line-height: 1.5rem;
            overflow: hidden;

        }
    }
}
