.awards {
    overflow: hidden;

    .photos {
        position: absolute;
        top:0;
        left:0px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        img {
            height: 100%;
            margin: 0 0.2rem 0 0.2rem;
        }
    }
}
