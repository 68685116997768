.page-manage {
    .detail-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: space-between;
        justify-content: space-around;
        width: 100%;
        height: 30%;
        border: 0px solid red;

        &-item {
            display: flex;
            align-items: center;
            position: relative;
            padding: 0 0.125rem;
            width: 48%;
            height: 95%;
            margin-bottom: 0.525rem;
            border-radius: 5px;
            border: 1px solid #343f4b;
            background-color: rgba(19, 25, 47, 0.8);

            img {
                width: 3.25rem;
                height: 3.25rem;
                margin-right: 1rem;
            }

            .detail-item-text {
                margin-left: 0.125rem;

                h3 {
                    color: #bcdcff;
                    font-size: 16px;
                    margin-bottom: 0.25rem;
                }

                span {
                    font-weight: 500px;
                    font-size: 0.25rem;
                    font-weight: bolder;
                    background: linear-gradient(to bottom, #fff, #4db6e5);
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                }

                .unit {
                    font-size: 0.2rem;
                    margin-left: 0.125rem;
                }
            }
        }
    }
}
