.title-container {
    position: absolute;
    border:0px solid red;
    width:80%;
    height:90%;
    top:0;
    left:10%;
    text-align:center;

    .task-schedule {
        width: 100%;
        height: calc(100% - 5rem)
    }
}
