.bigNumberContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;

    .item {
        box-sizing: border-box;
        width: 50%;
        height: 50%;
        margin:0;
        padding:0;
    }
}
