.page-body {

    &-left {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 60%;
        height: 100%;
        margin: 0;
        padding: 0;

        &-top {
            width: 100%;
            height: 50%;
            box-sizing: border-box;
            background-color: transparent;
        }

        &-bottom {
            width: 100%;
            height: 50%;
            box-sizing: border-box;
            background-color: transparent;
        }
    }

    &-right {
        position: absolute;
        left: 60%;
        top: 0;
        width: 40%;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
}

