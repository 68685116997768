.atten {
    h3 {
        font-size:0.5rem !important;
    }

    .messages {
        width: 100%;
        height: calc(100% - 3.5rem)
    }
}
