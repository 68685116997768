.bigContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;

    .dv-digital-flop {
        margin-top:20%;
        width: 100%;
        height: 50%;
    }

    .bigName {
        width: 100%;
        height: 30%;
        font-size: 0.5rem;
        text-align:center;
        color:#999999;
    }
}
