html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.textOverHandle{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

// 自定义模块
.default-chart{
    width: 100%;
    height: 100%;
}

body {
    background: url(assets/images/background-v2.jpg);
    background-size: cover;
}
.flex {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .item {
        width: 33.3%;
        border: 0px solid #ccc;
        box-sizing: border-box;
        height: 33.3%;
        margin:0;
        padding:0;
    }
    .itemLarge {
        width: 50%;
        border: 0px solid #ccc;
        box-sizing: border-box;
        height: 50%;
        margin:0;
        padding:0;
    }
}

.box-title {
    color: #999999;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 10;
    font-size: 0.8rem;
}

.page {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    height: calc(100% - 1rem);
    width: calc(100% - 1rem);
    margin: 0;
    padding: 0;
    border: 0px solid green;

    &-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1rem;
        box-sizing: border-box;
        margin: 0;
        padding: 0.1rem 0 0 0.5rem;
        border: 0px solid red;
        color: #aaaaaa;
        font-size: 1.0rem;

        i {
            font-size: 0.55rem;
            margin-right: 0.3rem;
        }
    }

    &-body {
        position: absolute;
        top: 1rem;
        left: 0;
        width: 100%;
        height: calc(100% - 1rem);
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        flex: 1;
        border: 0px solid yellowgreen;

        &-left-half {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            width: 50%;
            height: 100%;
            margin: 0;
            padding: 0;

            &-top {
                width: 100%;
                height: 50%;
                box-sizing: border-box;
            }

            &-bottom {
                width: 100%;
                height: 50%;
                box-sizing: border-box;
                background-color: transparent;
            }
        }

        &-right-half {
            position: absolute;
            display: flex;
            flex-direction: column;
            left: 50%;
            top: 0;
            width: 50%;
            height: 100%;
            overflow: hidden;
            box-sizing: border-box;
            margin: 0;
            padding: 0;

            &-top {
                width: 100%;
                height: 50%;
                box-sizing: border-box;
            }

            &-bottom {
                width: 100%;
                height: 50%;
                box-sizing: border-box;
                background-color: transparent;
            }
        }

    }

    .inner-container {
        position: relative;
        margin: 0.5rem;
        width: calc(100% - 1rem);
        height: calc(100% - 1rem);
        box-sizing: border-box;
        padding: 0;
    }

}

.dv-scroll-board .rows .ceil {
    font-size: 115%;
}
