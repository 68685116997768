.activities-container {
    overflow: hidden;

    .activities {
        position: absolute;
        top:0;
        left:0px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .activity {

            width: 100%;
            height: 20rem;
            display: block;

            .image {
                width: 100%;
                height: 12rem;
                margin: 0 0.2rem 0 0.2rem;
                border-radius: 0.5rem;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .title {
                width: 100%;
                height: 2rem;
                margin: 0.5rem 0.2rem 0 0.2rem;
                color:#4db6e5;
                text-align:center;
            }
        }
    }
}
